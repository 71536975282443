import { Graphql } from '@models/graphql';

export function extractHeroFromParagraphs(paragraphs: Graphql[]): Graphql[] {
  return paragraphs.filter((node) => isContentTypeHero(node));
}

export function isContentTypeHero(node: Graphql) {
  return node.type === 'paragraph__paragraph_hero_slider'
    || node.type === 'paragraph__paragraph_hero'
    || node.type === 'paragraph__paragraph_banner_form'
    || node.type === 'paragraph__paragraph_banner_talent_apply'
    || node.type === 'paragraph__paragraph_hero_fullscreen'
    || node.type === 'paragraph__paragraph_hero_video'
    || node.type === 'paragraph__paragraph_hero_video_slider'
    || node.type === 'paragraph__paragraph_hero_banner_webform';
}
