import React from 'react';
import { graphql } from 'gatsby';

import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';

const { industries: crumbs } = require('@config/breadcrumbs');

type IndustryProps = {
  language: string;
  data: {
    industry: Graphql;
  };
};

export default function IndustryPage(
  { data, language }: IndustryProps
): React.ReactElement {
  const { industry } = data;
  const paragraphs = industry?.relationships?.paragraphs;

  if (paragraphs && paragraphs.length <= 0) {
    return <></>;
  }

  const content = paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: industry.path }, language)
  );

  const hero = isContentTypeHero(paragraphs?.[0] as Graphql) ? content?.shift() : <></>;

  return (
    <div className="page-components-container">
      {hero}
      <Breadcrumb crumbs={crumbs.concat({ label: industry.title, link: '#' })} />
      {content}
    </div>
  );
}

export const query = graphql`
query($id: String!) {
  industry: nodeIndustry(id: { eq: $id }) {
    title
    body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
